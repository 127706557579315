<template>
  <section class="mt-2 mb-4 w-full h-full" id="filtro-reporte">
    <div class="accordion-item">
      <div id="faq-accordion-content-1" class="accordion-header flex justify-between">
        <button
          class="accordion-button text-sm font-medium focus:outline-none text-primary-1 dark:text-white"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#faq-accordion-collapse-1"
          aria-expanded="true"
          aria-controls="faq-accordion-collapse-1"
        >
          Filtros de busqueda
        </button>
<!--        <pre>{{ JSON.stringify(filters, null, '\t') }}</pre>-->
      </div>
      <div id="faq-accordion-collapse-1" class="accordion-collapse collapse show mt-2"
           aria-labelledby="faq-accordion-content-1" data-bs-parent="#faq-accordion-1">
        <div class="accordion-body text-gray-700 dark:text-gray-600 leading-relaxed">
          <div class="grid grid-cols-12 gap-x-6 gap-y-2">
            <div class="col-span-12 sm:col-span-3">
              <label for="departamento" class="block text-sm font-medium text-gray-700">Departamento:</label>
              <select
                class="form-select form-select-sm mt-1"
                id="departamento"
                v-model="filters.departamentoId"
                @change="fetchCiudades"
              >
                <!-- <option :value="null">-- Seleccione una opción --</option> -->
                <option v-for="(row,k) in listDepartamentos" :key="k" :value="row.id">{{ row.name }}</option>
              </select>
            </div>
            <div class="col-span-12 sm:col-span-3">
              <label for="ciudad" class="block text-sm font-medium text-gray-700">Municipio:</label>
              <select
                class="form-select form-select-sm mt-1"
                id="ciudad"
                v-model="filters.ciudadId"
                @click="validateDepartamentoSelected"
              >
                <!-- <option :value="null">-- Seleccione una opción --</option> -->
                <option v-for="(row,k) in listCiudades" :key="k" :value="row.id">{{ row.name }}</option>
              </select>
            </div>
            <div class="col-span-12 sm:col-span-3">
              <label for="search" class="block text-sm font-medium text-gray-700">Dispositivo:</label>
              <input
                type="text"
                class="form-control mt-1"
                style="height: 30px"
                placeholder="Buscar por nombre o serial del dispositivo..."
                v-model="filters.querySearch"
              >
            </div>
            <div class="col-span-12 lg:col-span-1 flex flex-wrap content-end">
              <Button
                label="Buscar"
                icon="pi pi-search"
                class="p-button-xs w-full"
                style="height: 30px"
                @click="updateFilter"
              />
            </div>
            <div class="col-span-12 lg:col-span-1 flex flex-wrap content-end">
              <Button
                label="Limpiar"
                icon="pi pi-trash"
                class="p-button-xs w-full p-button-danger"
                style="height: 30px"
                @click="resetClear"
              />
            </div>
            <div class="col-span-12 lg:col-span-1 flex flex-wrap content-end">
              <Button
                label="Exportar"
                icon="pi pi-file-excel text-sm"
                class="p-button-xs w-full p-button-success"
                style="height: 30px"
                @click="exportExcel(filters)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { onMounted, ref } from 'vue'
import { apiGetDepartamentos, apiGetCiudades } from '../../../services/locations.service'
import { messageWarning } from '../../../../../../libs/mensajes'

export default {
  name: 'index',
  props: {
    modelValue: {
      type: Object,
      required: true
    }
  },
  setup (props, context) {
    const filters = ref(props.modelValue)
    const listDepartamentos = ref([])
    const listCiudades = ref([])

    /**
     * @description Obtiene los departamentos por ID del pais
     */
    const fetchDepartamentos = async () => {
      try {
        const queryParams = {
          hasDispositivos: 1
        }
        const idPais = '61a7b63262c066e408abe8c9'
        const { data } = await apiGetDepartamentos(idPais, queryParams)
        listDepartamentos.value = data
        listDepartamentos.value.unshift({ id: '', name: 'Todos' })
      } catch (error) {
        console.log(error)
      }
    }

    /**
     * @description Obtiene las ciudades por ID del departamento
     */
    const fetchCiudades = async () => {
      try {
        const queryParams = {
          hasDispositivos: 1
        }
        const idDepartamento = props.modelValue.departamentoId
        if (idDepartamento) {
          const { data } = await apiGetCiudades(idDepartamento, queryParams)
          listCiudades.value = data
          listCiudades.value.unshift({ id: '', name: 'Todos' })
        } else {
          listCiudades.value = []
          filters.value.ciudadId = ''
        }

        context.emit('info', { departamentos: listDepartamentos.value, ciudades: listCiudades.value })
      } catch (error) {
        console.log(error)
      }
    }

    /* Validar que al menos seleccionó un departamento para listar los municipios */
    const validateDepartamentoSelected = () => {
      if (!props.modelValue.departamentoId) {
        messageWarning('Si desea filtrar por municipio debe elegir un departamento.')
        return false
      }
    }

    /* Exportar a excel */
    const exportExcel = (e) => {
      console.log('exportar excel')
      context.emit('update:modelValue', e)
      context.emit('downloadExcel', { filters: e })
    }

    /* Actualizar los filtros de busqueda */
    const updateFilter = () => {
      context.emit('update:modelValue', filters.value)
      context.emit('search', { filters: filters.value })
    }

    /* Resetear y limpiar los filtros de busqueda */
    const resetClear = () => {
      filters.value.departamentoId = ''
      filters.value.ciudadId = ''
      filters.value.querySearch = ''
      context.emit('update:modelValue', filters.value)
      context.emit('search', { filters: filters.value })
    }

    /* Hook */
    onMounted(() => {
      fetchDepartamentos()
    })

    return {
      listDepartamentos,
      listCiudades,
      updateFilter,
      resetClear,
      filters,
      fetchCiudades,
      validateDepartamentoSelected,
      exportExcel
    }
  }
}
</script>

<style scoped>

</style>
