import http from '../../../../libs/http'

const api = `${process.env.VUE_APP_MICROSERVICE_API_SENSPRO}`

const apiGetDepartamentos = (id, query) => {
  return http.get(`${api}/locations/paises/${id}/departamentos`, { params: { ...query } })
}

const apiGetCiudades = (id, query) => {
  return http.get(`${api}/locations/departamentos/${id}/ciudades`, { params: { ...query } })
}

export {
  apiGetDepartamentos,
  apiGetCiudades
}
